<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import axios from "axios";
import { addDays, format } from "date-fns";

export default {
    methods: {
        toggleFavourite(ele) {
            ele.target.closest('.favourite-btn').classList.toggle("active");
        },
      fetchData: async function (timeout) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            const {data} = await axios.get(
                `${
                    process.env.VUE_APP_URL_API
                }?filter[<DATE_MODIFY]=${this.tomorrow}&filter[>DATE_MODIFY]=${this.now}`
            );

            setTimeout(async () => {
              resolve(await data.result);
            }, timeout);
          } catch (err) {
            reject(err);
          }
        });
      }
    },
    page: {
        title: "Lead",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            now: format(new Date(), "yyyy-MM-dd"),
            tomorrow: format(addDays(new Date(), 1), "yyyy-MM-dd"),
            title: "Lead",
            items: [
                {
                    text: "Pages",
                    href: "/",
                },
                {
                    text: "Lead",
                    active: true,
                },
            ],
        };
    },
    components: {
        Layout
    },
    async mounted() {
      // const dataFetch = await this.fetchData(3000);
      //
      // console.log(
      //     'dataFetch',
      //     dataFetch
      // )
    }

};
</script>

<template>
    <Layout>

      <b-row class="mb-3">
        <b-row>
          <b-col lg="12">
            <div class="tab-content text-muted">
              <div class="tab-pane fade show active" id="project-team" role="tabpanel">

                <div class="team-list list-view-filter">
                  <b-card no-body class="team-box">
                    <b-card-body class="px-4">
                      <b-row class="align-items-center team-row">
                        <div class="col team-settings">
                          <b-row class="align-items-center">
                            <b-col>
                              <div class="flex-shrink-0 me-2">
                                <button type="button" class="btn fs-16 p-0 favourite-btn">
                                  <i class="ri-star-fill"></i>
                                </button>
                              </div>
                            </b-col>
                            <div class="col text-end dropdown">
                              <b-link href="javascript:void(0);" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                <i class="ri-more-fill fs-17"></i>
                              </b-link>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-eye-fill text-muted me-2 align-bottom"></i>Ver
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-star-fill text-muted me-2 align-bottom"></i>Favorito
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Eliminar
                                  </b-link>
                                </li>
                              </ul>
                            </div>
                          </b-row>
                        </div>
                        <b-col lg="4" cols>
                          <div class="team-profile-img">
                            <div class="avatar-lg img-thumbnail rounded-circle">
                              <img src="@/assets/images/users/avatar-2.jpg" alt=""
                                   class="img-fluid d-block rounded-circle" />
                            </div>
                            <div class="team-content">
                              <b-link href="#" class="d-block">
                                <h5 class="fs-16 mb-1">Nancy Martino</h5>
                              </b-link>
                              <p class="text-muted mb-0">Team Leader & HR</p>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="4" cols>
                          <b-row class="text-muted text-center">
                            <b-col cols="6" class="border-end border-end-dashed">
                              <h5 class="mb-1">225</h5>
                              <p class="text-muted mb-0">Projects</p>
                            </b-col>
                            <b-col cols="6">
                              <h5 class="mb-1">197</h5>
                              <p class="text-muted mb-0">Tasks</p>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="2">
                          <div class="text-end">
                            <router-link to="/lead-detail" class="btn btn-light view-btn">Ver Detalle</router-link>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>

                  <b-card no-body class="team-box">
                    <b-card-body class="px-4">
                      <b-row class="align-items-center team-row">
                        <div class="col team-settings">
                          <b-row class="align-items-center">
                            <b-col>
                              <div class="flex-shrink-0 me-2">
                                <button type="button"
                                        class="btn fs-16 p-0 favourite-btn active">
                                  <i class="ri-star-fill"></i>
                                </button>
                              </div>
                            </b-col>
                            <div class="col text-end dropdown">
                              <b-link href="javascript:void(0);" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                <i class="ri-more-fill fs-17"></i>
                              </b-link>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-eye-fill text-muted me-2 align-bottom"></i>Ver
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-star-fill text-muted me-2 align-bottom"></i>Favoritu
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Eliminar
                                  </b-link>
                                </li>
                              </ul>
                            </div>
                          </b-row>
                        </div>
                        <b-col lg="4" cols>
                          <div class="team-profile-img">
                            <div class="avatar-lg img-thumbnail rounded-circle">
                              <div class="avatar-title bg-soft-danger text-danger rounded-circle">
                                HB
                              </div>
                            </div>
                            <div class="team-content">
                              <b-link href="#" class="d-block">
                                <h5 class="fs-16 mb-1">Henry Baird</h5>
                              </b-link>
                              <p class="text-muted mb-0">Full Stack Developer</p>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="4" cols>
                          <b-row class="text-muted text-center">
                            <b-col cols="6" class="border-end border-end-dashed">
                              <h5 class="mb-1">352</h5>
                              <p class="text-muted mb-0">Projects</p>
                            </b-col>
                            <b-col cols="6">
                              <h5 class="mb-1">376</h5>
                              <p class="text-muted mb-0">Tasks</p>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="2">
                          <div class="text-end">
                            <router-link to="/lead-detail" class="btn btn-light view-btn">Ver
                              Detalle</router-link>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>

                  <b-card no-body class="team-box">
                    <b-card-body class="px-4">
                      <b-row class="align-items-center team-row">
                        <div class="col team-settings">
                          <b-row class="align-items-center">
                            <b-col>
                              <div class="flex-shrink-0 me-2">
                                <button type="button"
                                        class="btn fs-16 p-0 favourite-btn active">
                                  <i class="ri-star-fill"></i>
                                </button>
                              </div>
                            </b-col>
                            <div class="col text-end dropdown">
                              <b-link href="javascript:void(0);" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                <i class="ri-more-fill fs-17"></i>
                              </b-link>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-eye-fill text-muted me-2 align-bottom"></i>Ver
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-star-fill text-muted me-2 align-bottom"></i>Favorito
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Eliminar
                                  </b-link>
                                </li>
                              </ul>
                            </div>
                          </b-row>
                        </div>
                        <b-col lg="4" cols>
                          <div class="team-profile-img">
                            <div class="avatar-lg img-thumbnail rounded-circle">
                              <img src="@/assets/images/users/avatar-3.jpg" alt=""
                                   class="img-fluid d-block rounded-circle" />
                            </div>
                            <div class="team-content">
                              <b-link href="#" class="d-block">
                                <h5 class="fs-16 mb-1">Frank Hook</h5>
                              </b-link>
                              <p class="text-muted mb-0">Project Manager</p>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="4" cols>
                          <b-row class="text-muted text-center">
                            <b-col cols="6" class="border-end border-end-dashed">
                              <h5 class="mb-1">164</h5>
                              <p class="text-muted mb-0">Projects</p>
                            </b-col>
                            <b-col cols="6">
                              <h5 class="mb-1">182</h5>
                              <p class="text-muted mb-0">Tasks</p>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="2">
                          <div class="text-end">
                            <router-link to="/lead-detail" class="btn btn-light view-btn">Ver
                              Detalle</router-link>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>

                  <b-card no-body class="team-box">
                    <b-card-body class="px-4">
                      <b-row class="align-items-center team-row">
                        <div class="col team-settings">
                          <b-row class="align-items-center">
                            <b-col>
                              <div class="flex-shrink-0 me-2">
                                <button type="button" class="btn fs-16 p-0 favourite-btn">
                                  <i class="ri-star-fill"></i>
                                </button>
                              </div>
                            </b-col>
                            <div class="col text-end dropdown">
                              <b-link href="javascript:void(0);" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                <i class="ri-more-fill fs-17"></i>
                              </b-link>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-eye-fill text-muted me-2 align-bottom"></i>Ver
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-star-fill text-muted me-2 align-bottom"></i>Favorito
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Eliminar
                                  </b-link>
                                </li>
                              </ul>
                            </div>
                          </b-row>
                        </div>
                        <b-col lg="4" cols>
                          <div class="team-profile-img">
                            <div class="avatar-lg img-thumbnail rounded-circle">
                              <img src="@/assets/images/users/avatar-8.jpg" alt=""
                                   class="img-fluid d-block rounded-circle" />
                            </div>
                            <div class="team-content">
                              <b-link href="#" class="d-block">
                                <h5 class="fs-16 mb-1">Jennifer Carter</h5>
                              </b-link>
                              <p class="text-muted mb-0">UI/UX Designer</p>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="4" cols>
                          <b-row class="text-muted text-center">
                            <b-col cols="6" class="border-end border-end-dashed">
                              <h5 class="mb-1">225</h5>
                              <p class="text-muted mb-0">Projects</p>
                            </b-col>
                            <b-col cols="6">
                              <h5 class="mb-1">197</h5>
                              <p class="text-muted mb-0">Tasks</p>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="2">
                          <div class="text-end">
                            <router-link to="/lead-detail" class="btn btn-light view-btn">Ver
                              Detalle</router-link>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>

                  <b-card no-body class="team-box">
                    <b-card-body class="px-4">
                      <b-row class="align-items-center team-row">
                        <div class="col team-settings">
                          <b-row class="align-items-center">
                            <b-col>
                              <div class="flex-shrink-0 me-2">
                                <button type="button" class="btn fs-16 p-0 favourite-btn">
                                  <i class="ri-star-fill"></i>
                                </button>
                              </div>
                            </b-col>
                            <div class="col text-end dropdown">
                              <b-link href="javascript:void(0);" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                <i class="ri-more-fill fs-17"></i>
                              </b-link>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-eye-fill text-muted me-2 align-bottom"></i>Ver
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-star-fill text-muted me-2 align-bottom"></i>Favorito
                                  </b-link>
                                </li>
                                <li>
                                  <b-link class="dropdown-item" href="javascript:void(0);"><i
                                      class="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Eliminar
                                  </b-link>
                                </li>
                              </ul>
                            </div>
                          </b-row>
                        </div>
                        <b-col lg="4" cols>
                          <div class="team-profile-img">
                            <div class="avatar-lg img-thumbnail rounded-circle">
                              <div
                                  class="avatar-title bg-soft-success text-success rounded-circle">
                                ME
                              </div>
                            </div>
                            <div class="team-content">
                              <b-link href="#" class="d-block">
                                <h5 class="fs-16 mb-1">Megan Elmore</h5>
                              </b-link>
                              <p class="text-muted mb-0">Team Leader & Web Developer</p>
                            </div>
                          </div>
                        </b-col>
                        <b-col lg="4" cols>
                          <b-row class="text-muted text-center">
                            <b-col cols="6" class="border-end border-end-dashed">
                              <h5 class="mb-1">201</h5>
                              <p class="text-muted mb-0">Projects</p>
                            </b-col>
                            <b-col cols="6">
                              <h5 class="mb-1">263</h5>
                              <p class="text-muted mb-0">Tasks</p>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="2">
                          <div class="text-end">
                            <router-link to="/lead-detail" class="btn btn-light view-btn">Ver
                              Detalle</router-link>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </div>

                <b-row class="g-0 text-center text-sm-start align-items-center mb-3">
                  <b-col sm="6">
                    <div>
                      <p class="mb-sm-0">Mostrando 1 to 10 of 12 entradas</p>
                    </div>
                  </b-col>
                  <b-col sm="6">
                    <ul
                        class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                      <li class="page-item disabled">
                        <b-link href="#" class="page-link"><i class="mdi mdi-chevron-left"></i></b-link>
                      </li>
                      <li class="page-item">
                        <b-link href="#" class="page-link">1</b-link>
                      </li>
                      <li class="page-item active">
                        <b-link href="#" class="page-link">2</b-link>
                      </li>
                      <li class="page-item">
                        <b-link href="#" class="page-link">3</b-link>
                      </li>
                      <li class="page-item">
                        <b-link href="#" class="page-link">4</b-link>
                      </li>
                      <li class="page-item">
                        <b-link href="#" class="page-link">5</b-link>
                      </li>
                      <li class="page-item">
                        <b-link href="#" class="page-link"><i class="mdi mdi-chevron-right"></i>
                        </b-link>
                      </li>
                    </ul>
                  </b-col>
                </b-row>

              </div>
            </div>
          </b-col>
        </b-row>
      </b-row>

    </Layout>
</template>
